(function() {
    const button = document.querySelector('.menu-toggle');
    const menuDropDown = document.querySelector('.menu-dropdown');
    const menu = document.querySelector('.main-nav');
    const form = document.getElementById('contact_form');
    const result = document.querySelector('.result');
    button.addEventListener('click', function(event) {
        event.preventDefault();
        menu.classList.toggle('is-open');
    })
    menuDropDown.addEventListener('click', function(event) {
        menu.classList.toggle('is-open');
    })
    form.addEventListener('submit', function(event) {
        event.preventDefault();
        const formData = new FormData(form);
        sendForm(formData);
        console.log(formData);
    })
    async function sendForm(formData){
        const response = await fetch('contact_form.php',{
            method: 'POST',
            body: formData
        });
        const data = await response.text();
        result.innerHTML = data;
        if (data.includes(`class="ok"`)) {
            form.reset();
        }
    }
})();